import React from 'react';


class SmallLoading extends React.Component{  
    constructor(props) {
        super(props);
        
        this.state = {
            content: this.props.children,
            hasLoaded: this.props.hasLoaded,

            title: this.props.title
        }
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        let content = prevState.children || nextProps.children,
        hasLoaded = (nextProps.hasLoaded === undefined)? prevState.hasLoaded: nextProps.hasLoaded;

        return {
            content: content,
            hasLoaded: hasLoaded
        };
    }

    render() { 
        let styling = {
            color: 'rgba(0,0,0,0.5)'
        }
        return (
            (this.state.hasLoaded)? this.state.content :
            <div className="pl-3" style={styling}>
                <div className="spinner-border spinner-border-sm text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
                {' ' + this.state.title}
            </div>
        )
    }
}

export default SmallLoading;