import axios from '../bin/axios_gsh';


class Tools{
    static logout() {
        // Destroys session
        try{
            return axios.get('auth/logout').then(function (response) {
                return true;
            });
        }
        catch(error) {
            return false;
        }
    }
}

export default Tools;