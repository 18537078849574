import React from 'react';


class Modal extends React.Component{  
    constructor(props) {
        super(props);
        this.state = {
            content: this.props.children,

            extraButton: this.props.extraButton || "",
            closeButtonText: this.props.closeButtonText || "Stäng",
            outsideClickCloses: (this.props.outsideClickCloses === undefined)? true : this.props.outsideClickCloses,
        };
        this.outsideModal = this.outsideModal.bind(this);
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        let content = prevState.children || nextProps.children,
        extraButton = prevState.extraButton || nextProps.extraButton;

        return {
            content: content,
            extraButton: extraButton
        };
    }
    outsideModal(e) {
        e.preventDefault();
        if(this.state.outsideClickCloses) {
            if(e.currentTarget === e.target) {
                this.props.setModal(false);
            }
        }
    }
    render() { 
        return (
            <div onClick={ this.outsideModal } className="modal fixed-modal fade show" tabIndex="-1" role="dialog" aria-labelledby="orderModal" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button onClick={(e) => { e.preventDefault(); this.props.setModal(false); }} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            { this.state.content }
                        </div>
                        <div className="modal-footer">
                            { this.state.extraButton }
                            <button onClick={ (e) => { e.preventDefault(); this.props.setModal(false); }} type="button" className="btn btn-secondary" data-dismiss="modal">{ this.state.closeButtonText }</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Modal;