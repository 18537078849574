import React from 'react';
import Modal from './components/Modal.js';
import Table from './components/Table.js';
import { Link } from 'react-router-dom';
import DisplayError from './components/DisplayError.js';
import Pagination from './components/Pagination.js';
import axios from './bin/axios_gsh';


class OrderSaved extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            openModal: false,
            modal: null,
            inactive: null,
            userid: null,
            companyid: null,

            view: {
                type: "user",
                id: null
            },

            headers: ['Användare', 'Namn', ''],
            skipDataColumns: [],
            identifierIndex: 0,
            hasLoaded: false,

            data: [],
            templates: [],
            users: [],
            error: null,

            page: 0,
            perPage: 8,
            clickedTemplate: null,

            cartErrorModal: false,
            validationErrors: []
        };

        this.prepareDeleteTemplate = this.prepareDeleteTemplate.bind(this);
        this.deleteTemplate = this.deleteTemplate.bind(this);
        this.addToCart = this.addToCart.bind(this);
        this.addTemplate = this.addTemplate.bind(this);
        this.toggleFavorite = this.toggleFavorite.bind(this);
        this.openUserTemplates = this.openUserTemplates.bind(this);
        this.openCompanyTemplates = this.openCompanyTemplates.bind(this);
        this.setModal = this.setModal.bind(this);
        this.setValidationModal = this.setValidationModal.bind(this);
    }
    static getDerivedStateFromProps(prevState, nextProps) {
        let access = nextProps.access,
        skipDataColumns = nextProps.skipDataColumns || prevState.skipDataColumns;

        return {
            access: access,
            skipDataColumns: skipDataColumns
        };
    }
    componentWillUnmount() {
    }
    componentDidMount() {
        let self = this;

        ( axios.get('user/current').then(function (response) {

            let result = response.data;

            if(!result.error) {
                let userid = result.ID;
                let companyid = result.ID_Kund;

                let admin = (result.ID_Användare === null);
                self.setState({
                    view: {
                        type: "user",
                        id: userid
                    },
                    userid: userid,
                    companyid: companyid,
                    admin: admin
                }, () => {
                    self.loadData();
                    if(!admin) {
                        self.setState({
                            users: [{
                                ID: userid,
                                Email: result.Email,
                                Namn: result.Namn
                            }]
                        });
                        
                    }else{
                        ( axios.get('user/company/users').then(function (companyResponse) {
                            let users = companyResponse.data;
                            self.setState({
                                users: users
                            });
                        }));
                    }
                }); 
            } else {
                self.setState({
                    error: <DisplayError {...result.error} />
                });
            }
        }));
    }

    loadData() {
        let self = this;
        let view = this.state.view;

        if(view.type === "user") {
            axios.get('order/template/user/' + view.id).then(function (response) {
                let result = response.data;
                if(!result.error) {
                    self.setState ({
                        templates: result,
                        hasLoaded: true,
                    });
                } else{
                    result.hasLoaded = true;
                    self.setState({
                        result
                    });
                }
            }).then(() => {
                self.updateData();
            });
        }else{
            axios.get('order/template/company').then(function (response) {
                let result = response.data;
                
                if(!result.error) {
                    self.setState({
                        templates: result,
                        hasLoaded: true,
                    })
                } else{
                    result.hasLoaded = true;
                    
                    self.setState(
                        result
                    );
                }
            }).then(() => {
                self.updateData();
            });
        }
    }
    updateData() {
        let self = this;
        let newData = [];

        this.state.templates.forEach(template => {
            let ownerOrAdmin = (this.state.userid === template.ID_Användare || this.state.admin);
            let newTemplate = {
                FullNamn: null,
                Namn: null,
                Form: null
            };

            newTemplate.FullNamn = (template.FullNamn)? template.FullNamn : "";
            newTemplate.Namn = <Link to={ "/lists/list/" + template.ID }>{ template.Namn }</Link>;
            newTemplate.Form = <div>
                {(ownerOrAdmin)? <button onClick={ self.prepareDeleteTemplate } type="submit primary" className="btn btn-danger float-right ml-2 icon-custom small" value={ template.ID } alt="Ta bort"><img src="/../icon/trash2.0.png" alt="Ta bort" /></button> : ""}
                <button onClick={(e) => {e.preventDefault(); self.setState({clickedTemplate: template.ID}, () => {self.addToCart(false)})} }  rel="tooltip" data-toggle="tooltip" data-placement="bottom" title="Lägg till i varukorgen" type="submit primary" className="btn btn-gsh float-right ml-2" value={ template.ID }><span className="d-sm-none">Varukorg</span><span className="d-none d-sm-inline">Lägg till</span></button>
                {(this.state.admin)? <button onClick={ self.toggleFavorite } type="submit primary" className={ "btn float-right ml-2" + ((template.Publik)? " btn-gsh" : " btn-light") } value={ template.ID }>Publicera</button> : ""}
            </div>;
           

            if(ownerOrAdmin) {
                newData.unshift(newTemplate);
            }else{
                newData.push(newTemplate);
            }
        });

        self.setState({
            skipDataColumns: (this.state.view.type !== "company")? [0] : [],
            data: newData
        });
    }

    toggleFavorite(e) {
        e.preventDefault();

        let self = this;
        let target = e.currentTarget;

        if(target.value) {
            let id = parseInt(target.value, 10);
            let found = false;
            
            for (let i = 0; i < this.state.templates.length; i++) {
                const order = this.state.templates[i];
                if(order.ID === id) {
                    found = true;
                    break;
                }
            }
            
            if(found) {
                ( axios.post('order/template/favorite', { templateid: id }).then(function (response) {
                    let result = response.data;
                    if(!result.error) {
                        self.loadData();
                    }else{
                        self.setState({
                            error: <DisplayError {...result.error}/>
                        });
                    }
                })); 
            }
            
            
        } 
    }
    addTemplate(e) {
        e.preventDefault();
        let target = e.target;

        if(target.templateName) {
            let name = target.templateName.value;
            
            axios.post('order/template/add/', {name: name}).then(function (response) {
                response = response.data;
            }).then(() => {
                this.loadData();
            }); 
        }   
    }
    deleteTemplate(e) {
        e.preventDefault();
        let target = e.currentTarget;


        this.setState({
            openModal: false,
        });
        if(target.value) {
            let found = false,
            id = parseInt(target.value, 10);
    
            for (let i = 0; i < this.state.templates.length; i++) {
                const order = this.state.templates[i];
                if(order.ID === id) {
                    found = true;
                    break;
                }
            }
            
            if(found) {
                axios.delete('order/template/delete', {data: {templateid: id}}).then(function (response) {
                    response = response.data;
                }).then(() => {
                    this.loadData();
                });
            }
        }
    }

    openUserTemplates(e) {
        e.preventDefault();
        let target = e.currentTarget;

        if(target.value) {
            let id = parseInt(target.value, 10);
            let found = false;
            
            for (let i = 0; i < this.state.users.length; i++) {
                const user = this.state.users[i];
                if(user.ID === id) {
                    found = true;
                    break;
                }
            }

            if(found) {
                this.setState({
                    view: {
                        type: "user",
                        id: id
                    },
                    hasLoaded: false
                }, () => {
                    this.loadData();
                });
            }
        }
    }
    openCompanyTemplates(e) {
        e.preventDefault();

        this.setState({
            view: {
                type: "company",
                id: this.state.companyid
            },
            hasLoaded: false
        }, () => {
            this.loadData();
        });
    }
    
    prepareDeleteTemplate(event) {
        event.preventDefault();

        let extraButton = <button onClick={this.deleteTemplate} type="button" className="btn btn-gsh" value={event.currentTarget.value}>Ta bort</button>;
        let modal = 
            <Modal extraButton={extraButton} setModal={this.setModal} closeButtonText={"Avbryt"} outsideClickCloses={false} >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Ta bort Inköpslistan</h2>
                            <p>Är du säker på att du vill ta bort denna Inköpslistan?</p>
                        </div>
                    </div>
                </div>
            </Modal>;

        this.setState({
            openModal: true,
            modal: modal,
        });
    }
    setModal(open) {
        this.setState({
            openModal: open
        });
    }
    setValidationModal(open) {
        this.setState({
            cartErrorModal: open
        });
    }
    addToCart(force) {
        let self = this;
        let mallid = this.state.clickedTemplate;

        this.setState({
            openModal: false,
        });
        axios.post('cart/add/template/', {templateID: mallid, force: force}).then(function (response) {
            response = response.data;
            let errors = [];

            if(response.length > 0 && response[0].error){
                for (let i = 0; i < response.length; i++) {
                    
                    if(response[i].error.title === 'Artikeln hittades inte'){
                        errors = [];
                        errors.push('Ett fel har skett, en eller fler artiklar i varukorgen är utgångna.');
                        // Artikeln hittades inte i systemet
                        break;
                    }
                    

                    errors.push((response[i].error.data)? "Artikel nr." + response[i].error.data.Nummer + ", " + response[i].error.data.Benämning + ": " + response[i].error.description : response[i].error.title + ": " + response[i].error.description);
                }
            }

            if(response === true){
                self.props.updateCart();
            }

            self.setState({
                cartErrorModal: (self.state.cartErrorModal === false && errors.length !== 0),
                validationErrors: errors
            });
        });
    }
    render() {
        let orderTable = <Table page={this.state.page} perPage={this.state.perPage} hasLoaded={this.state.hasLoaded} visualFeedback={false} countRows={false} headers={this.state.headers} data={this.state.data} skipDataColumns={this.state.skipDataColumns} identifierIndex={this.state.identifierIndex}/>;
        let description = (this.state.access)? 'Som admin kan du publicera ordrar, då syns ordarna i menyn "Alla" för andra användare i företaget.' : "";
        let self = this;
        let setPage = function(page) {
            self.setState({
                page: page,
            });
        };

        let forceCartButton = <button onClick={ (e) => {e.preventDefault(); this.addToCart(true);} } type="button" className="btn btn-gsh" data-dismiss="modal">Lägg till ändå</button>;

        return(
            <div className="row">
                <div className="col">
                    {
                        (this.state.openModal)?
                        this.state.modal
                        : ""
                    }

                    {
                        this.state.cartErrorModal &&
                        <Modal outsideClickCloses={false} extraButton={forceCartButton} setModal={this.setValidationModal}  closeButtonText={"Avbryt"}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h2>Ett fel har skett</h2>
                                        <p>Vissa av varorna i listan går inte att lägga till i varukorgen, vill du lägga till varorna ändå? Om du väljer att lägga till varorna i varukorgen ändå så kommer inte alla att komma med.</p>
                                        {this.state.validationErrors.map((error, i) => { return <p key={i}>{error}</p>})}
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    }
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-8">
                                    <h1>
                                        Inköpslistor
                                    </h1>
                                    <p className="lead">{ description }</p>
                                    { this.state.error }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="row">
                                <div className="col-md-12">
                                    <form onSubmit={ this.addTemplate }>
                                        <div className="input-group input-group-lg mb-3">
                                            <input type="text" className="form-control" name="templateName" id="templateName" placeholder="Namn" required/>
                                            <div className="input-group-append">
                                                <button type="submit primary" className="btn btn-primary rounded-right">Lägg till</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="border rounded">
                                        <div className="px-0 col-md-12">{ orderTable }</div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <Pagination setPage={setPage} current={this.state.page} pages={((this.state.data.length / this.state.perPage) % 1 !== 0)? Math.floor(this.state.data.length / this.state.perPage) + 1 : Math.floor(this.state.data.length / this.state.perPage)}>

                                    </Pagination>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <div className="row">
                                <div className="col-md-12">
                                    <hr className="d-lg-none mt-4"/>
                                    <h2 className="text-muted">Användare</h2>
                                    <p>Se inköpslistor av andra konton i företaget.</p>
                                    <div className="list-group">
                                        { this.state.users.map(
                                            (user, i) => 
                                                <button onClick={ this.openUserTemplates } type="submit primary" className={ "list-group-item list-group-item-action pointer" + ((((this.state.view !== null && this.state.view.id === user.ID)))? " bg-primary text-white" : "") } value={ user.ID } key={ i }>
                                                    <h6 className="my-0">{ user.Namn }</h6>
                                                    <small className="">
                                                        { user.Email }
                                                    </small>
                                                </button>
                                                
                                            )
                                        }
                                        <button onClick={ this.openCompanyTemplates }  className={ 'list-group-item list-group-item-action pointer' + ((this.state.view !== null && this.state.view.type === "company")? ' bg-primary text-white' : '')}>
                                            Alla
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default OrderSaved;