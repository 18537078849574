import React from 'react';
import {Link} from 'react-router-dom';
import axios from '../bin/axios_gsh';
import Table from './Table.js';

class SideCart extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            totalPrice: 0,
            header: ['productID', 'unitID', 'Nr', 'Benämning', 'Namn', '', 'Enhet','SEK', ''],
            products: [],
            showCart: false,
            skipDataColumns: [0, 1, 2, 3],

            unitNames: [    // Based on unit id's in table VY_Enhet
                '',
                'Kg', 
                'Antal', 
                'Kolli', 
            ]
        };

        this.openCart = this.openCart.bind(this);
        this.makeSmallCartArr = this.makeSmallCartArr.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    
    openCart() {
        this.setState(prevState => ({
            showCart: !prevState.showCart
        }));
    }

    componentDidMount() {
        this.makeSmallCartArr();
    }

    componentDidUpdate(prevProps) {
        if(JSON.stringify(prevProps.cart) !== JSON.stringify(this.props.cart)){
            this.makeSmallCartArr();
        }

        if(this.props.showCart !== prevProps.showCart) {
            this.setState({
                showCart: true
            });
        }
    }

    makeSmallCartArr() {
        let arr = [...this.props.cart];
        let products = [];
        for(let i = 0; i < arr.length; i++) {
            let product = {
                productID: arr[i].productID, 
                unitID: arr[i].unitID, 
                Nummer: arr[i].Nummer, 
                Benämning: arr[i].Benämning, 
                Namn: arr[i].Benämning + ' (' + arr[i].Nummer + ')', 
                amount: arr[i].amount, 
                unit: this.state.unitNames[parseInt(arr[i].unitID, 10)], 
                pris: arr[i].pris,
                delete: 
                    <button type="button" className="close" aria-label="Close" onClick={(e) => {this.handleDelete(e, {productID: arr[i].productID, unit: arr[i].unitID}); }}>
                        <span aria-hidden="true" >&times;</span>
                    </button>
            }

            products.push(
                product
            );
        }
        this.setState({
            products: products
        });
    }

    handleDelete(event, product) {
        event.preventDefault();
        let self = this;

        axios.post('cart/remove', product).then(function (response) {
            // Before Gustav visa användaren fel
            self.props.updateCart();
        });
    }

    render() {
        let cart = '';

        if(this.state.showCart) {
            cart =    
            <div>
                <div className="card shadow-sm mb-5 rounded " style={{position: 'fixed', right: 0, height: '87%', backgroundColor: 'white', zIndex: 1000, width: '20em' }}>
                    <div className="card-header" >
                        Varukorg 
                        <button type="button" className="close" aria-label="Close" onClick={this.openCart}>
                            <span aria-hidden="true" >&times;</span>
                        </button>
                    </div>
                    <div style={{overflowY: 'auto', height: '89%', overflowX: 'hidden'}}>
                        { (this.state.products !== null && this.state.products.length > 0 )? 
                            <Table style={{ tableLayout: 'fixed', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}} clickFunction={this.getItemNr} countRows={false} headers={this.state.header} skipDataColumns={this.state.skipDataColumns} data={this.state.products} identifierIndex={0}/>
                            : null 
                            }
                        </div>
                    
                    <div  style={{position: 'fixed', bottom: 0, right: 0, width: '20em'}}>
                        <Link to="/order">
                            <div className="btn btn-lg btn-gsh col-sm-12 rounded-0">
                                Gå Till Ordersida
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        } else {
            cart = 
            <div onClick={this.openCart} className="show-cart rounded-left shadow-sm mb-5 pointer" style={{height: '200px', backgroundColor: '#28a745', zIndex: 1000}}>
                
            </div>
        }
        return (
            <div style={{position: 'absolute', right: 0}}>
                {cart}      
            </div>
        );
    }
}

export default SideCart;