import React from 'react';

class ProductMessage extends React.Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        event.preventDefault();
        let message = this.refs.productMessage.value;

        this.props.updateMessage(message);
    }

    render() {
        return(
            <div className="customer-control">
                <input type="text" className="form-control" ref="productMessage" onChange={this.handleChange} value={this.props.message} placeholder="Meddelande"/>
            </div>
        );
    }
}

export default ProductMessage;