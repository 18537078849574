import React from "react";
import SmallLoading from './SmallLoading';

class UpdateAmountForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: "",
      unit: "",
      tempAmount: this.props.meta.amount,
      tempUnit: this.props.meta.unitID,

      infoMessage: null,
      valid: true,

      step: 1,
      kg: 1,
      st: 2,
      kolli: 3,

      Förpackningsstorlek: this.props.meta.Förpackningsstorlek,

      hasClicked: false,
      selectText:
        this.props.meta.unitID === 1
          ? "Vikt"
          : this.props.meta.unitID === 2
          ? "Antal"
          : "Kolli",
      stage: 0,

      bufferIndex: 0,
    };

    this.onClick = this.onClick.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeUnit = this.onChangeUnit.bind(this);
    this.onChangeAmount = this.onChangeAmount.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount() {
    let value = 1;
    switch (this.props.meta.unitID) {
      case this.state.kg:
        value = 0.1;
        break;
      case this.state.st:
        value = this.props.meta.Stegom;
        break;
      case this.state.kolli:
        break;
      default:
        break;
    }
    this.setState({
      step: value,
    });
  }
  componentDidUpdate(props, prevState) {
    if (this.props.meta.amount !== props.meta.amount) {
      this.setState({
        tempAmount: this.props.meta.amount,
      });
    }
    if (this.props.meta.unitID !== props.meta.unitID) {
      this.setState({
        tempUnit: this.props.meta.unitID,
        selectText:
          this.props.meta.unitID === 1
            ? "Vikt"
            : this.props.meta.unitID === 2
            ? "Antal"
            : "Kolli",
      });
    }
    if (
      this.props.meta.productID !== props.meta.productID &&
      this.props.meta.unitID !== props.meta.unitID
    ) {
      this.setState({
        valid: true,
        step:
          this.tempUnit === this.state.st
            ? this.props.meta.Stegom
            : this.tempUnit === this.state.kg
            ? 0.1
            : 1,
      });
    }

    if(
      this.props.meta.unitID != props.meta.unitID || this.props.meta.amount != props.meta.amount){
        this.setState({loading: false})
    }
  }
  /*
    static getDerivedStateFromProps(nextProps, prevState) {
        let meta = nextProps.meta;

        let amount = (meta.amount)? meta.amount : prevState.amount;
        //let unit = (meta.unitID)? meta.unitID : prevState.unitID;
        //let Förpackningsstorlek = (meta.Förpackningsstorlek)? meta.Förpackningsstorlek : prevState.Förpackningsstorlek;
        //let selectText = (meta.unitID === 1)? 'Vikt' : (meta.unitID === 2)? 'Antal' : 'Kolli';

        return({
            amount: amount,
        });
        unit: unit,
        Förpackningsstorlek: Förpackningsstorlek,
        selectText: selectText
    }*/

  onChangeAmount(event) {
    let amount = event.currentTarget.value;

    this.setState({
      tempAmount: amount,
    });
  }

  onChangeUnit(selectedUnit) {

    let unit = parseInt(selectedUnit, 10);

    let textVal = "";
    let value = 0;
    switch (unit) {
      case this.state.kg:
        value = 0.1;
        textVal = "Vikt";
        break;
      case this.state.st:
        value = this.props.meta.Stegom;
        textVal = "Antal";
        break;
      case this.state.kolli:
        value = 1;
        textVal = "Kolli";
        break;
      default:
        // Unhandled
        break;
    }
    this.setState({
      selectText: textVal,
      tempUnit: unit,
      step: value,
    });
  }
  onClick(event) {
    event.preventDefault();

    this.setState({
      hasClicked: true,
    });
  }
  onSubmit(event) {
    event.preventDefault();
    const index = this.state.bufferIndex + 1;

    if (this.state.stage === 0) {
      this.setState({
        stage: 1,
      });
    } else {
      var amount = this.state.tempAmount;
      var unit = parseInt(this.state.tempUnit, 10);
      let oneDecimal = new RegExp("(^\\d*(\\.|\\,)\\d{1}$)|(^\\d+$)");
      let integer = new RegExp("(^\\d+$)");

      if (!amount || amount <= 0) {
        this.setState({
          valid: false,
          infoMessage: "Du har inte matat in antal som är större än 0.",
        });
      } else if (!oneDecimal.test(amount)) {
        this.setState({
          valid: false,
          infoMessage: "Minsta viktenheten är 0,1 kg.",
        });
      } else if (
        unit === this.state.st &&
        amount % this.props.meta.Stegom > 0
      ) {
        this.setState({
          valid: false,
          infoMessage:
            "Denna artikel köpes om " + this.props.meta.Stegom + " st.",
        });
      } else if (
        !integer.test(amount) &&
        (unit === this.state.st || unit === this.state.kolli)
      ) {
        this.setState({
          valid: false,
          infoMessage: "Enheten kan endast hantera heltal.",
        });
      } else {
        this.setState({
          valid: true,
          infoMessage: null,

          hasClicked: false,
          stage: 0,
          selectText:
            this.props.meta.unitID === 1
              ? "Vikt"
              : this.props.meta.unitID === 2
              ? "Antal"
              : "Kolli",
          //amount: (unit !== 3)? amount % this.props.meta.Förpackningsstorlek : amount
        });

        let newProduct = {
          productID: this.props.meta.productID,
          amount: parseFloat(amount),
          unit,
        };
        let oldProduct = {
          productID: this.props.meta.productID,
          amount: this.props.meta.amount,
          unit: this.props.meta.unitID,
        };

        this.setState(
          {
            loading: true,
            bufferIndex: index,
          },
          () => {
            setTimeout(() => {
              if (this.state.bufferIndex === index) {
                this.props.updateProduct(oldProduct, newProduct);
              }
            }, 1000);
          }
        );
      }
    }
  }
  onCancel(event) {
    event.preventDefault();
    let self = this;
    const { amount, unitID } = this.props.meta

    this.setState({
      tempAmount: amount,

      hasClicked: true,
      stage: 0,
    }, () => {
      self.onChangeUnit(unitID);
    });
  }
  render() {
    let dropdown;
    let amount = (
      <input
        type="number"
        onChange={this.onChangeAmount}
        className={
          "form-control col amountform" +
          (this.state.valid ? "" : " is-invalid")
        }
        aria-label=""
        placeholder={this.state.selectText}
        step={this.state.step}
        min={0}
        value={this.state.tempAmount}
        required
      />
    );

    if (!this.props.meta.Ej_Delbar) {
      if (this.props.meta.Pris_per_styck) {
        dropdown = (
          <div className="input-group-append col px-0">
            <button
              onClick={this.onClick}
              className={
                "btn dropdown-toggle rounded-0 border-right-0 w-100 btn-gsh"
              }
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {this.state.selectText}
            </button>
            <div className="dropdown-menu pointer">
              <button
                onClick={(e) => {e.preventDefault(); this.onChangeUnit(e.currentTarget.value)}}
                className="dropdown-item"
                type="button"
                value={this.state.kolli}
              >
                Kolli
              </button>
              <button
                onClick={(e) => {e.preventDefault(); this.onChangeUnit(e.currentTarget.value)}}
                className="dropdown-item"
                type="button"
                value={this.state.st}
              >
                Antal (st)
              </button>
            </div>
          </div>
        );
        /*
                <select ref="unit" onChange={this.onChange} className="form-control">
                    <option value={3}>Kolli</option>
                    <option value={2}>Antal (st)</option>
                </select>;
                /* ({this.props.Förpackningsstorlek} st) */
      } else {
        dropdown = (
          <div className="input-group-append col px-0">
            <button
              onClick={this.onClick}
              className={
                "btn dropdown-toggle rounded-0 border-right-0 w-100 btn-gsh"
              }
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {this.state.selectText}
            </button>
            <div className="dropdown-menu pointer">
              <button
                onClick={(e) => {e.preventDefault(); this.onChangeUnit(e.currentTarget.value)}}
                className="dropdown-item"
                type="button"
                value={this.state.kolli}
              >
                Kolli
              </button>
              <button
                onClick={(e) => {e.preventDefault(); this.onChangeUnit(e.currentTarget.value)}}
                className="dropdown-item"
                type="button"
                value={this.state.kg}
              >
                Vikt (Kg)
              </button>
            </div>
          </div>
        );

        /*
                    <select ref="unit" onChange={this.onChange} className="form-control">
                        <option value={3}>Kolli</option> 
                        <option value={1}>Vikt (kg)</option>
                    </select>*/
      }
    } else {
      dropdown = (
        <div className="input-group-append col px-0">
          <button
            className="btn btn-secondary dropdown-toggle rounded-0 border-right-0 ej-delbar w-100"
            type="button"
            data-toggle="tooltip"
            data-placement="bottom"
            title="Ej Delbar"
            aria-haspopup="true"
            aria-expanded="false"
            disabled
          >
            Kolli
          </button>
          <div className="dropdown-menu pointer">
            <button
              className="dropdown-item"
              type="button"
              value={this.state.kolli}
            >
              Kolli
            </button>
          </div>
        </div>
      );

      /*
                <select ref="unit" className="form-control"  rel="tooltip" data-toggle="tooltip" data-placement="bottom" title={"Går ej att dela. Kan enbart beställa denna vara i enheter av kollin."} disabled>
                    <option value={3}>Kolli (Ej delbar)</option>
                </select>;
            */
    }
    let fields = (
      <div className="w-100">
        <div className="input-group w-100">
          {this.state.stage === 0 ? (
            <input
              type="text"
              className="form-control amountform amount"
              value={this.state.tempAmount}
              disabled
            />
          ) : (
            ""
          )}
          {this.state.stage === 0 ? dropdown : ""}
          {this.state.stage === 1 ? amount : ""}
          <div className={"input-group-append col-auto px-0"}>
            {this.state.stage === 0 ? (
              <button
                onClick={this.onSubmit}
                rel="tooltip"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Ändra"
                className={
                  "btn icon-custom small amountform float-right btn-gsh"
                }
                disabled={this.state.loading}
              >
                <SmallLoading title={""} hasLoaded={!this.state.loading} >
                  <img
                    src="/../icon/edit.png"
                    alt="Ändra"
                    style={{ objectFit: "scale-down" }}
                  />
                </SmallLoading>
              </button>
            ) : (
              <button
                onClick={this.onSubmit}
                style={{
                  backgroundImage: "url('/../icon/baseline-done-24px.svg')",
                }}
                rel="tooltip"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Utför ändring"
                className="btn btn-svg btn-gsh sm rounded-0"
                type="button"
              ></button>
            )}
          </div>
          {this.state.stage === 1 ? (
            <div className="input-group-append">
              <button
                onClick={this.onCancel}
                rel="tooltip"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Avbryt"
                className="btn btn-danger amountform float-right"
              >
                <i className="font-weight-bold icon rotate">+</i>
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
        {!this.state.valid ? (
          <div
            className="alert alert-warning mt-1 mb-0 w-100"
            style={{ fontSize: "0.7rem" }}
            role="alert"
          >
            {this.state.infoMessage}
          </div>
        ) : (
          ""
        )}
      </div>
    );

    return fields;
  }
}

export default UpdateAmountForm;
