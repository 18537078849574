import React, {Component} from 'react'
import axios from '../bin/axios_gsh';
import PriceList from './PriceList';
import {Switch, Route, Router, Link} from 'react-router-dom';
import history from './BrowserHistory';

class NavSide extends Component{
    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            filter: (this.props.filter !== null)? this.props.filter : [
                {name: 'Eko', checked: false},
                {name: 'Krav', checked: false}, 
                {name: 'Närodlat', checked: false},
                {name: 'Svenskt', checked: false},
                {name: 'Avtalsartiklar', checked: false}, 
            ],
            isLoaded: false,
        }
        this.Catalog = this.Catalog.bind(this);
        this.loadData = this.loadData.bind(this);
        this.setFilter = this.setFilter.bind(this);
    }
    componentDidMount() {
        this.loadData();
    }
    componentDidUpdate() {
        if(!this.state.isLoaded) {
            this.loadData();
        }
    }
    setFilter(e) {
        let target = e.currentTarget;

        let index = this.state.filter.findIndex(element => element.name === target.name);
        //let current = this.state.filter[index].checked;

        let newFilter = JSON.parse(JSON.stringify(this.state.filter));

        newFilter[index].checked = !this.state.filter[index].checked;

        this.props.setFilter(newFilter);
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        let filter = (nextProps.filter)? nextProps.filter : prevState.filter;

        return({
            filter: filter
        });
    }

    loadData() {
        let self = this;

        axios.get('product/categories').then(function (response) {
            response = response.data;
    
            let categories = [];
            response.forEach(element => {
                let temp = {
                    id: element.ID,
                    name: element.Namn,
                    isLoading: false
                };
                categories.push(temp);
            });

            self.setState({
                categories: categories, 
                isLoaded: true
            });
        });
    }
    
    render() {
        return(
            <nav className="sidebar h-100 d-xl-inline-block navbar-collapse collapse mt-4" id="sidebar">
                <div className="nav-list">
                    <div className="float-left">
                        <button className="btn btn-danger d-block d-xl-none position-relative float-right rounded-circle text-center" id="sidebar-toggle" type="button" data-toggle="collapse" data-target="#sidebar" aria-controls="sidebar" aria-expanded="false" aria-label="Toggle navigation">
                            
                        </button>
                    </div>

                    {
                        (this.props.user === null)?"":
                        <div className="bg-white px-3 rounded mini-menu">
                            <h6 className="font-weight-bold py-3 mb-0 d-flex justify-content-between">
                                <span>Kategorier</span>
                                <a className="d-flex align-items-center text-dark collapse-button" data-toggle="collapse" href="#categoriesCollapse" aria-expanded="true" aria-controls="categoriesCollapse">
                                    
                                </a>
                            </h6>
                            <div className="collapse multi-collapse overflow-hidden show pb-3" id="categoriesCollapse" >
                                <ul className="nav flex-column">
                                    <li className="nav-item">
                                        <Link className={("nav-link" + (this.props.view === 'campaign' ? " active" : ""))} to={"/stock/campaign"}>Kampanjvaror</Link>
                                    </li>
                                    {
                                        this.state.categories.map((category, i) =>
                                            <li className={("nav-item")} key={i}>
                                               <Link className={("nav-link" + (category.id+'' === this.props.categoryID? " active" : ""))} to={"/stock/category/"+category.id}>{category.name}</Link>
                                            </li>
                                        )
                                    }
                                    <li className="nav-item">
                                        <hr/>
                                    </li>
                                    <li className="nav-item">
                                        <Link className={("nav-link" + (this.props.view === 'agreement' ? " active" : ""))} to={"/stock/agreement"}>Avtalsartiklar</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className={("nav-link" + (this.props.view === 'all' ? " active" : ""))} to={"/stock/all"}>Alla artiklar</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    }
                    <Router history={history}>
                        <Switch>
                            <Route path="/index" component={ this.Catalog } />
                            <Route path="/stock" component={ this.Catalog }/>
                            <Route path="/product" component={ this.Catalog }/>
                        </Switch>
                    </Router>
                </div>
                
                {/*
                <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                    <span>Saved reports</span>
                    <a className="d-flex align-items-center text-muted" >
                    </a>
                </h6>
                <ul className="nav flex-column mb-2">
                    <li className="nav-item">
                        <a className="nav-link" >
                        Current month
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" >
                        Last quarter
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" >
                        Social engagement
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" >
                        Year-end sale
                        </a>
                    </li>
                </ul>*/
                }
            </nav>
        );
    }
    Catalog() {
        if(this.props.user !== null) {
            return(
                <div>
                    <div className="bg-white px-3 rounded mini-menu mt-4">
                        <h6 className="font-weight-bold py-3 mb-0 d-flex justify-content-between text-muted">
                            <span>Filter</span>
                            <a className="d-flex align-items-center text-muted collapse-button" data-toggle="collapse" href="#filterCollapse" role="button" aria-expanded="false" aria-controls="filterCollapse">
                                
                            </a>
                        </h6>
                        <div className="collapse multi-collapse overflow-hidden show pb-3" id="filterCollapse">
                            <ul className="nav flex-column">
                                {
                                    (this.state.filter !== null && this.state.filter.length > 0)?
                                        this.state.filter.map((filter, i) =>
                                            <div className="nav-item" key={i}>
                                                <div className="form-check">
                                                    <input type="checkbox" className="form-check-input pointer" name={filter.name} checked={filter.checked} onChange={this.setFilter} value={filter.name}/>
                                                    <label className="form-check-label" >
                                                        {filter.name}
                                                    </label>
                                                </div>
                                            </div>
                                        ) 
                                        : 
                                        null
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            );
        }
        return(
            ""
        );
    }
}

export default NavSide;