import React from 'react';
import Form from './components/Form.js';
import axios from './bin/axios_gsh';
import DisplayError from './components/DisplayError.js';

class LoginForm extends Form{

    constructor(props) {
        super(props);
        this.state = {
            user: {

            },

            username: '',
            password: '',
            error: null,
        }
    }
    
    validateForm() {
        return this.state.username.length > 0 && this.state.password.length > 0;
    }
    handleSubmit(event) {
        event.preventDefault();
        let self = this;
        
        (axios.post('auth/login', {login: this.state.username, password: this.state.password}).then(function (response) {
            let result = response.data;
            if(!result.error) {
                window.__csrf_token = result.csrfToken;
                let axiosDefaults = axios.defaults;
                axiosDefaults.headers.common['X-CSRF-Token'] = result.csrfToken;

                self.props.setLogin(result);
                self.props.updateCart();
            }else{
                if(response.status === 500) {
                    // Möjligtvis två användare med samma login

                    self.setState({
                        error: <DisplayError title="Ett fel har uppstått." />
                    })
                }else{
                    self.setState({
                        error: <DisplayError {...result.error} />
                    })
                }
            }
        }));
    }
    render() {
        let  isIE = /*@cc_on!@*/false || !!document.documentMode;
        let message = '';

        let body = '';
        if(isIE) {
            message = <div className="text-center">
                        <h3 >Denna sidan fungerar inte i Internet Explorer</h3><br/>
                        <h3>Använd t ex någon av dessa webbläsare istället:  </h3>
                        
                            <h4>Chrome 	&nbsp;&bull;&nbsp; Edge &nbsp;&bull;&nbsp; Firefox &nbsp;&bull;&nbsp; Safari</h4>
                    </div>
        } else 
        {
            body = 
            <div className="row justify-content-center">
                <div className="col-sm-10 col-md-8 col-lg-6 p-5 mt-5 border rounded bg-white">
                    <form onSubmit={this.handleSubmit} >
                        <div className="row">
                            <div className="form-group col-md-12">
                                <h1 className="form-header">Välkommen</h1>
                                <p>Var vänlig och logga in för att komma åt hemsidan.</p>
                                {
                                    this.state.error
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-md-12">
                                <label htmlFor="username" className="sr-only">Användarnamn</label>
                                <input type="text" data-parse="lowercase" value={this.state.username} onChange={this.handleChange} className="form-control" name="username" id="username" placeholder="Användarnamn" required autoFocus/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-md-12">
                                <label htmlFor="password" className="sr-only">Lösenord</label>
                                <input type="password" value={this.state.password} onChange={this.handleChange} className="form-control" name="password" id="password" placeholder="Lösenord" required/>
                                <p className="form-text text-muted m-0" id="gh_passwordHelp"><small>Kom ihåg att lösenordet är teckenkänsligt. {/*<a style={{'display':'none'}} href="">Har du glömt ditt lösenord?</a>*/}</small></p>
                            </div>
                        </div>
                        <hr/>
                        <div className="row">
                            <div className="form-group col-md-12 mb-0">
                                <button onClick={this.handleSubmit} type="primary submit" className="btn btn-lg btn-primary btn-block" disabled={!this.validateForm()}>Logga in</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        
        }

        return(
            <div>
                <div className="container">
                {message}
                {body}
                </div>

            </div>
        );
    }
}

export default LoginForm;