import React from 'react';
import axios from './bin/axios_gsh';
import Form from './components/Form.js';
import DisplayError from './components/DisplayError.js';

class AccountDetails extends Form{
    constructor(props) {
        super(props);
        let user = {};
        user.user = this.props
        user.password = "";
        user.confirmPassword = "";
        user.users = [];
        
        this.state = {
            sublabel: this.props.sublabel,
            access: false,
            alertActive: null,
            alertText: [],
            alertStyle: {
                'danger': "alert alert-danger",
                'warning': "alert alert-warning",
                'success': "alert alert-success",
            },
            company: {
                kund: '',
                kundID: '',
                ort: '',
                adress1: '',
                postnummer: '',
                plockDagar: '',
                stoppTid: '',
                land: '',
                landskod: '',
                        
                FakturaKund: '',
                FakturaOrt: '',
                FakturaAdress1: '',
                FakturaPostnummer: '',
                FakturaLand: '',
                FakturaLandskod: ''
            },
            user: {
                admin: false,
                aktiv: false,
                userid: null,
                name: "",
                login:  "",
                email: "",
                password: "",
                confirmPassword: "",
            },
            addUser: false,
            newPassword: false,
            newPasswordLength: 6,
            showDeliveryAdress: true,
            users: [],

            error: null,
        }
        this.cancelPasswordUpdate = this.cancelPasswordUpdate.bind(this);
        this.viewUser = this.viewUser.bind(this);
        this.viewFormAddUser = this.viewFormAddUser.bind(this);
        this.toggleActiveUser = this.toggleActiveUser.bind(this);
        this.loadData = this.loadData.bind(this);
    }
    componentDidMount() {
        let self = this;

        (axios.get('auth/admin').then(function (response) {
            let admin = response.data.admin;
            self.setState({
                access: admin,
            }, () => {
                self.loadData().then((result) => {
                    result.company = (result.company)? result.company : self.state.company;
                    result.user = (result.user)? result.user : self.state.user;
                    result.users = (result.users)? result.users : self.state.users;
                    result.error = (result.error)? result.error : "";
                    
                    self.setState({
                        company: result.company,
                        user:  result.user,
                        users: result.users,
                        error: result.error,
                    });
                });
            });
        }));
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        let access = prevState.access || nextProps.access,
        company = prevState.company || nextProps.company,
        user = prevState.user || nextProps.user,
        users = prevState.users || nextProps.users;

        return {
            access: access,
            company: company,
            user: user,
            users: users
        };
    }
    reload() {
        let self = this;

        this.loadData().then((result) => {
            result.company = (result.company)? result.company : self.state.company;
            result.user = (result.user)? result.user : self.state.user;
            result.users = (result.users)? result.users : self.state.users;
            result.error = (result.error)? result.error : "";
            result.alertActive = null;
            self.setState(
                result
            );
            
        });

    }
    reloadUserList() {
        let self = this;
        if(self.state.access) {
            return ( axios.get('user/company/users').then(function (response) {
                response = response.data;

                self.setState({
                    users: response
                })
            }));
        }

    }
    loadData() {
        let self = this;

        let userid = (this.state.user.userid && this.state.access)? this.state.user.userid : "current";
        return ( axios.get('user/' + userid).then(function (response) {
            response = response.data;
            if(!response.error) {
                if(self.state.access) {
                    return ( axios.get('user/company/users').then(function (companyResponse) {
                        companyResponse = companyResponse.data;
                        return {
                            company: {
                                kund: response.Företag,
                                kundID: response.ID_Kund,
                                ort: response.Ort,
                                adress1: response.Adress1,
                                postnummer: response.Postnummer,
                                plockDagar: response.AntalPlockdagar,
                                stoppTid: response.StoppTid,
                                land: response.Land,
                                landskod: response.ID_CountryCode,
                            
                                FakturaKund: response.FakturaFöretag,
                                FakturaOrt: response.FakturaOrt,
                                FakturaAdress1: response.FakturaAdress,
                                FakturaPostnummer: response.FakturaPostnummer,
                                FakturaLand: response.FakturaLand,
                                FakturaLandskod: response.FakturaCountryCode,
                                FakturaMail: response.FakturaMail,
                                FakturaKontakt: response.FakturaKontakt
                            },
                            user: {
                                admin: (response.ID_Användare === null),
                                aktiv: response.Aktiv,
                                userid: response.ID,
                                name: response.Namn,
                                login:  response.Login,
                                email: response.Email,
                                password: self.state.user.password,
                                confirmPassword: self.state.user.confirmPassword,
                            },
                            newPassword: self.state.newPassword,
                            users: companyResponse
                        };
                    }));
                }else{
                    return ({
                        company: {
                            kund: response.Företag,
                            kundID: response.ID_Kund,
                            ort: response.Ort,
                            adress1: response.Adress1,
                            postnummer: response.Postnummer,
                            plockDagar: response.AntalPlockdagar,
                            stoppTid: response.StoppTid,
                            land: response.Land,
                            landskod: response.ID_CountryCode,
                            
                            FakturaKund: response.FakturaFöretag,
                            FakturaOrt: response.FakturaOrt,
                            FakturaAdress1: response.FakturaAdress,
                            FakturaPostnummer: response.FakturaPostnummer,
                            FakturaLand: response.FakturaLand,
                            FakturaLandskod: response.FakturaCountryCode,
                            FakturaMail: response.FakturaMail,
                            FakturaKontakt: response.FakturaKontakt
                        },
                        user: {
                            admin: (response.ID_Användare === null),
                            aktiv: response.Aktiv,
                            userid: response.ID,
                            name: response.Namn,
                            login:  response.Login,
                            email: response.Email,
                            password: self.state.user.password,
                            confirmPassword: self.state.user.confirmPassword,
                        },
                        newPassword: self.state.newPassword,
                        users: []
                    });
                }
            }else{
                let err = <DisplayError {...response.error} />;
                return (
                    {
                        error: err
                    }
                );
            }
        }));
    }
    viewUser(e) {
        let target = e.currentTarget;

        if(target && target.value) {
            this.setState({
                addUser: false,
            }, () => {
                let found = false;
                let userid = parseInt(target.value, 10);
                
                for (let i = 0; i < this.state.users.length; i++) {
                    const user = this.state.users[i];
                    if(user.ID === userid) {
                        found = true;
                        break;
                    }
                }

                if(found) {
                    let user = this.state.user;
                    user.userid = userid;
                    this.setState({
                        user: user
                    }, () => {
                        this.loadData().then((result) => {
                            result.user.password = "";
                            result.user.confirmPassword = "";
                            result.newPassword = false;
                            result.alertActive = false;
                            this.setState(
                                result
                            );
                        });
                    });
                }
            });
        }
    }
    viewFormAddUser(event) {
        event.preventDefault();
        this.setState({
            addUser: true,
            user: {
                admin: false,
                aktiv: false,
                userid: null,
                name: "",
                login:  "",
                email: "",
                password: "",
                confirmPassword: "",
            },
            newPassword: false,
            alertActive: false
        });
    }
    beforeChange(event) {
        if(event.target.name === "password" && this.state.newPassword === false) {
            this.setState({
                newPassword: true
            });
        }
    }
    handleChange(event) {
        this.beforeChange(event);
        
        let user = JSON.parse(JSON.stringify(this.state.user));
        user[event.target.name] = event.target.value;
        
        this.setState({
            user: user
        });
        
    }
    cancelPasswordUpdate(event) {
        event.preventDefault();
        
        let user = this.state.user;
        user.password = "";
        user.confirmPassword = "";

        this.setState({
            user: user,
            newPassword: false,
            alertActive: false
        });
    }
    handleSubmit(event) {
        event.preventDefault();

        if(this.state.addUser) {
            this.addUser();
        }else{
            this.updateUser();
        }

    }
    showDeliveryAdress(event, open) {
        event.preventDefault();

        this.setState({
            showDeliveryAdress: open
        })
    }
    updateUser() {
        let self = this;

        (axios.get('auth/admin').then(function (response) {
            let admin = response.data.admin,
            allowed = false;

            ( axios.get('user/current').then(function (response2) {
                let result = response2.data;
                if(!result.error) {
                    if(admin) {
                        // Can only edit accounts belonging to company
                        allowed = self.state.company.kundID === result.ID_Kund;
                    }else{
                        // Can only edit themself
                        allowed = self.state.user.userid === result.ID;
                    }
                    if(allowed) {
                        let user = {
                            userid: self.state.user.userid,
                            name: self.state.user.name
                        };
                        
                        if (self.state.newPassword) {
                            if( (self.state.user.password !== self.state.user.confirmPassword) || 
                            self.state.user.password.length < self.state.newPasswordLength) {
                                return false;
                            }else{
                                user.password = self.state.user.password;
                                user.confirmPassword = self.state.user.confirmPassword;
                            }
                        }
                        ( axios.post('user/update', user).then(function ( response ) {
                            let alert = "",
                            alertText = [];
                            
                            if(response.status === 500) {
                                alert = "danger";
                                alertText.push("Ett fel har uppstått.");
                            }else{
                                alert = "success";
                                alertText.push("Ändringarna har blivit sparade!");
                            }

                            self.setState({
                                alertActive: alert,
                                alertText: alertText
                            }, () => {
                                self.reloadUserList();
                            });
                        }));
                    }
                } else {
                    self.setState({
                        error: <DisplayError {...response.error} />
                    });
                }
            }));
        }));
    }
    addUser() {
        let self = this,
        user = {
            name: this.state.user.name,
            login:  this.state.user.login,
            email: this.state.user.email,
        };
        if (this.state.newPassword) {
            if((this.state.user.password !== this.state.user.confirmPassword)) {
                this.setState({
                    alertActive: "warning",
                    alertText: ["Den nya användaren behöver ett lösenord."]
                });
                //return false;
            }else{
                user.password = this.state.user.password;
                user.confirmPassword = this.state.user.confirmPassword;
            }
        }
        ( axios.post('user/add', user).then(function ( response ) {
            let result = response.data;
            let alert = "success",
            alertText = [];

            if(result.error) {
                alert = "warning"
                for (let i = 0; i < result.error.length; i++) {
                    const error = result.error[i];
                    alertText.push(error.msg);
                }
                if(result.error === "En användare med detta användarnamn eller E-Mail existerar redan.") {
                    alertText.push(result.error);
                }
            }else{
                self.setState({
                    error: <DisplayError {...response.error} />
                })
                alertText.push("Användaren har blivit tillagd!")
            }
            self.setState({
                alertActive: alert,
                alertText: alertText
            }, () => {
                self.reloadUserList();
            });
        }));
    }
    toggleActiveUser(event) {
        event.preventDefault();
        let self = this,
        user = { 
            userid: self.state.user.userid
        };

        return ( axios.post('user/activate', user).then(function (response) {
            self.reload();
        }));
    }
    render() {
        let users = (this.state.access)?
            <div className="row">
                <div className="col-md-12">
                    <hr className="d-lg-none mt-4"/>

                    <h2 className="text-muted">Användare</h2>
                    <p>Se andra konton i företaget</p>
                    <div className="d-block w-100 float-left pb-3">
                        <button onClick={ this.viewFormAddUser } rel="tooltip" data-toggle="tooltip" data-placement="bottom" type="button" className="btn btn-primary float-right">Lägg till Konto</button>
                    </div>
                    <div className="list-group w-100">
                        { 
                            this.state.users.map(
                                (user, i) => 
                                <button onClick={ this.viewUser } className={ "list-group-item list-group-item-action pointer" + ((this.state.user.userid === user.ID)? " bg-primary text-white" : "") } value={ user.ID } key={ i }>
                                    <h6 className="my-0">{ user.Namn }</h6>
                                    <small className="">
                                        { user.Email }
                                    </small>
                                </button>
                            )
                        }
                    </div>
                    <div className="d-block w-100 float-left pt-3">
                        <button onClick={ this.toggleActiveUser } rel="tooltip" data-toggle="tooltip" data-placement="bottom" type="button" className={ (this.state.user.admin)? "btn btn-secondary float-right" : (this.state.user.aktiv)? "btn btn-danger float-right" : "btn btn-primary float-right" } disabled={this.state.user.admin}>{(this.state.user.aktiv)? "Inaktivera konto" : "Aktivera konto" }</button>
                    </div>
                </div>
            </div> : "";
        
        let confirmPassword = (this.state.newPassword)?
            <div className="form-group col-md-12">
                <label htmlFor="confirmPassword">Upprepa Lösenord</label>
                <div className="input-group">
                    <input type="password" onChange={this.handleChange} className={ "form-control" + ((this.state.user.password === this.state.user.confirmPassword)? "" : " is-invalid") } name="confirmPassword" id="confirmPassword" value={ this.state.user.confirmPassword } required={ true }/>
                    <div className="input-group-append">
                        <button type="button" onClick={ this.cancelPasswordUpdate } className="btn btn-danger rounded-right">Avbryt</button>
                    </div>
                    <div className="invalid-feedback">
                        Lösenorden matchar inte.
                    </div>
                </div>
            </div> : "";
            
        let stoppTid = new Date(this.state.company.stoppTid);
        stoppTid = (((stoppTid.getUTCHours() < 10)? "0"+stoppTid.getUTCHours() : stoppTid.getUTCHours()) + ":" + ((stoppTid.getUTCMinutes() < 10)? "0"+stoppTid.getUTCMinutes() : stoppTid.getUTCMinutes()) + ":" + ((stoppTid.getUTCSeconds() < 10)? "0"+stoppTid.getUTCSeconds() : stoppTid.getUTCSeconds()));
        return(
            <div className="row">
                <div className="col">
                    <div className="row">
                        <div className="col-md-12">
                            <h1>
                                { (this.state.addUser)? "Lägg till användare" : "Kontouppgifter" } 
                            </h1>
                            <p className="lead">{ (this.state.addUser)? "Fyll i formuläret nedan" : "Ändra på uppgifter för kontot" } </p>
                            { this.state.error }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <form onSubmit={ this.handleSubmit } className="needs-validation" noValidate>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="col-md-12 p-5 border rounded bg-white">
                                            <div className="row">
                                                <div className="form-group col-md-12">
                                                    <label htmlFor="name">Namn</label>
                                                    <input type="text" onChange={this.handleChange} className="form-control" name="name" id="name" aria-describedby="helpId" placeholder="" value={ this.state.user.name }/>
                                                    <small id="helpId" className="form-text text-muted"></small>
                                                    
                                                </div>
                                                
                                                {/* <div className="form-group col-md-6">
                                                    <label htmlFor="surname">Efternamn</label>
                                                    <input type="text" onChange={this.handleChange} className="form-control" name="surname" id="surname" aria-describedby="helpId" placeholder="" defaultValue="Namnsson"/>
                                                    <small id="helpId" className="form-text text-muted"></small>
                                                </div> */}
                                                <div className="form-group col-md-12">
                                                    <hr/>
                                                    <label htmlFor="username">Användarnamn</label>
                                                    <div className="input-group">
                                                        <input type="text" onChange={this.handleChange} className="form-control" name="login" id="login" value={ this.state.user.login } required={ !this.state.addUser } disabled={ !this.state.addUser }/>
                                                        <div className="invalid-feedback">
                                                            Your username is required.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <label htmlFor="email">Email</label>
                                                    <div className="input-group">
                                                        <input type="text" onChange={this.handleChange} className="form-control" name="email" id="email" aria-describedby="helpId" placeholder="" value={ this.state.user.email } required={ !this.state.addUser } disabled={ !this.state.addUser }/>
                                                        <small id="helpId" className="form-text text-muted"></small>
                                                    </div>
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <label htmlFor="username">{ ((this.state.user.userid !== null)? "Nytt " : "") }Lösenord</label>
                                                    <div className="input-group">
                                                        <input type="password" onChange={ this.handleChange } className={ "form-control" + (((this.state.user.password).length < this.state.newPasswordLength && this.state.newPassword && this.state.user.id !== null)? " is-invalid" : "") } id="password" name="password" value={ this.state.user.password } placeholder="******"  required={ !this.state.addUser }/>
                                                    </div>
                                                    <small id="passwordHelpBlock" className="form-text text-muted">
                                                        Lösenordet måste vara minst { this.state.newPasswordLength } tecken långt.
                                                    </small>
                                                </div>
                                                <div className="slow-expand w-100">
                                                    { confirmPassword }
                                                </div>
                                            </div>
                                            {
                                                ((this.state.alertActive)? 
                                                    (<div className={ this.state.alertStyle[this.state.alertActive] + " mt-3 mb-0" } role="alert">
                                                        {   
                                                            this.state.alertText.map((alert, i) => {
                                                                return <p className="mb-0" key={i}>{alert}</p>
                                                            })
                                                        }
                                                    </div>)
                                                : "")
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-md-12 mb-0">
                                        <hr className="mt-4" />
                                        <button onClick={ this.handleSubmit } type="submit primary" name="" id="" className="btn btn-primary btn-lg btn-block">Spara</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <div className="row">
                                <div className="col-md-12">
                                    <hr className="d-lg-none mt-4"/>
                                    <h2 className="text-muted">Företagsinformation</h2>
                                    <p></p>

                                    <ul className="nav nav-tabs">
                                        <li className="nav-item">
                                            <button onClick={ (event) => { this.showDeliveryAdress(event, true) } } className={"nav-link" + ((this.state.showDeliveryAdress)? " active" : " nav-link border-bottom pointer text-muted") } >Leverans</button>
                                        </li>
                                        <li className="nav-item">
                                            <button onClick={ (event) => { this.showDeliveryAdress(event, false) } } className={"nav-link" + ((!this.state.showDeliveryAdress)? " active" : " nav-link border-bottom pointer text-muted") } >Fakturering</button>
                                        </li>
                                    </ul>
                                    {(this.state.showDeliveryAdress)? 
                                        <ul className="list-group mb-3">
                                            <li className="list-group-item d-flex justify-content-between lh-condensed rounded-0 border-top-0">
                                                <div>
                                                    <h6>Adress</h6>
                                                    <p className="mb-0">
                                                        <small>
                                                            { (this.state.company.kund || "Företag") }
                                                            <br />
                                                            { (this.state.company.adress1 || "Adress") }
                                                            <br />
                                                            { (this.state.company.postnummer || "Postnummer")+ ", " + (this.state.company.ort || "Ort") + (((this.state.company.landskod === 710)? "" : " " + this.state.company.land)) }
                                                            <br />
                                                            { }
                                                        </small>
                                                    </p>
                                                </div>
                                            </li>
                                            <li className="list-group-item d-flex justify-content-between lh-condensed">
                                                <div>
                                                    <h6>Intervall</h6>
                                                    <p className="mb-0">
                                                        <small>
                                                            { "Vid beställning innan klockan " + stoppTid + " så sker leverans efter " + (this.state.company.plockDagar || 1) + (((parseInt(this.state.company.plockDagar, 10) || 1) === 1)? " dag" : " dagar") + "." }
                                                        </small>
                                                    </p>
                                                </div>
                                            </li>
                                        </ul>
                                    :
                                        <ul className="list-group mb-3">
                                            <li className="list-group-item d-flex justify-content-between lh-condensed rounded-0 border-top-0">
                                                <div>
                                                    <h6>Adress</h6>
                                                    <p className="mb-0">
                                                        <small>
                                                            { (this.state.company.FakturaKund || "Företag") }
                                                            <br />
                                                            { (this.state.company.FakturaAdress1 || "Adress") }
                                                            <br />
                                                            { (this.state.company.FakturaPostnummer || "Postnummer")+ ", " + (this.state.company.FakturaOrt || "Ort") + (((this.state.company.FakturaLandskod === 710)? "" : " " + this.state.company.FakturaLand)) }
                                                            <br />
                                                        </small>
                                                    </p>
                                                </div>
                                            </li>
                                            <li className="list-group-item d-flex justify-content-between lh-condensed">
                                                <div>
                                                    <h6>E-Mail</h6>
                                                    <p className="mb-0">
                                                        <small>
                                                            { (this.state.company.FakturaMail || "E-Mail") }
                                                        </small>
                                                    </p>
                                                </div>
                                            </li>
                                            {
                                                (this.state.company.FakturaKontakt !== null)?
                                                    <li className="list-group-item d-flex justify-content-between lh-condensed">
                                                        <div>
                                                            <h6>Kontakt</h6>
                                                            <p className="mb-0">
                                                                <small>
                                                                    { (this.state.company.FakturaKontakt || "Kontakt") }
                                                                </small>
                                                            </p>
                                                        </div>
                                                    </li>
                                                :
                                                ""
                                            }
                                        </ul>
                                    }
                                </div>
                            </div>
                            { users }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default AccountDetails;