import React from 'react';
import Order from './Order.js';
import Modal from './components/Modal.js';
import OrdersActive from './components/OrdersActive.js';
import OrderHistory from './components/OrderHistory.js';
import { Link } from 'react-router-dom';


class Orders extends React.Component{  
    constructor(props) {
        super(props);

        let self = this;
        this.state = {
            openModal: false,
            loadOrder: null,
            archived: false,

            onClickFunction: function(orderid, archived) {
                self.setState({
                    loadOrder: orderid,
                    archived: archived,
                    openModal: true
                });
            },
        };
        this.setModal = this.setModal.bind(this);
    }
    componentDidMount() {
    }
    componentWillUnmount() {
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        let loadOrder = prevState.loadOrder || nextProps.loadOrder;

        return {
            loadOrder: loadOrder,
        };
    }
    openOrder() {
        return <Order id={ this.state.loadOrder } archived={this.state.archived}/>;
    }
    setModal(open) {
        this.setState({
            openModal: open
        });
    }
    render() { 
        let openSeperate = <Link className="btn btn-primary" target="_blank" to={'/order/' + this.state.loadOrder}>Öppna</Link>
        return (
            <div className="row">
                <div className="col">
                    {
                        (this.state.openModal)?
                            <Modal extraButton={openSeperate} setModal={this.setModal} >
                                { this.openOrder() }
                            </Modal> : ''
                    }
                    {
                        <div>
                            <OrdersActive onClickFunction={ (orderid) => { this.state.onClickFunction(orderid, false) }} />
                            <hr className="my-3"/>
                            <OrderHistory onClickFunction={ (orderid) => { this.state.onClickFunction(orderid, true) }} />
                        </div>
                    }
                    
                    
                </div>
            </div>
        );
    }
}

export default Orders;