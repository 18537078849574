import React from 'react';

class SearchBar extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            data: props.data || [],
            searchResult: [],
            searchString: "",
            showNumber: props.showNumber || 50,
            parentClickFunction: props.clickFunction,
            disabled: props.inactivated,

            clickFunction: (e, object) => {
                if(!this.state.disabled) {
                    this.state.parentClickFunction(e, object);
                }
                /*
                this.setState({
                    searchString: e.target.name
                });*/
            },
        }
        this.handleChange = this.handleChange.bind(this);
        this.setSearchString = this.setSearchString.bind(this);
        this.updateList = this.updateList.bind(this);
        this.clickSearch = this.clickSearch.bind(this);
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        let data = (nextProps.data)? nextProps.data : prevState.data,
        searchResult = nextProps.searchResult || prevState.searchResult,
        searchString = nextProps.searchString || prevState.searchString,
        showNumber = nextProps.showNumber || prevState.showNumber,
        parentClickFunction = nextProps.clickFunction || prevState.clickFunction,
        disabled = nextProps.inactivated;

        return {
            data: data,
            searchResult: searchResult,
            searchString: searchString,
            showNumber: showNumber,
            parentClickFunction: parentClickFunction,
            disabled: disabled
        };
    }
    setSearchString(str) {
        this.setState({
            searchString: (!this.state.disabled)? str : "",
        });
    }
    handleChange(event) {
        this.setState({
            searchString: (!this.state.disabled)? event.target.value : "",
        },() => { this.search() });
    }
    search() {
        // Override
    }
    clickSearch() {
        // Override
    }
    updateList() {
        // Override
    }
    render() {
        return(
            <div className="input-group input-group-lg mb-3 search-wrapper">
                <input type="text" className="form-control rounded-0" name="search" id="search" placeholder="Sök efter en artikel" value={ this.state.searchString } onChange={ this.handleChange } disabled={ this.state.disabled } autoComplete="off"/>
                <div className="list-group col-md-12 px-0 position-absolute" id="search-results">
                    { this.state.searchResult }
                </div>
            </div>   
        );
    }
}

export default SearchBar;