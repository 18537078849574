import React from 'react';

class Pagination extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            current: 0,
            pages: 0,
        };
        this.previousPage = this.previousPage.bind(this);
        this.setPage = this.setPage.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.goToAnchor = this.goToAnchor.bind(this);
    }
    static getDerivedStateFromProps(props, prevState) {
        let pages =  props.pages || prevState.pages,
        current =  (props.current !== null)? props.current : prevState.current;

        return {
            pages: pages,
            current: current,
        }
    }
    previousPage(e) {
        let self = this;
        let page = (this.state.current > 0)? this.state.current - 1 : 0;

        self.props.setPage(page);
    }
    setPage(e) {
        let self = this;
        let value = parseInt(e.currentTarget.value, 10);
        let page = (value >= 0 && value <= this.state.pages - 1)? value : this.state.current;

        self.props.setPage(page);
    }
    nextPage(e) {
        let self = this;
        let page = (self.state.current < this.state.pages - 1)? self.state.current + 1 : this.state.pages - 1

        self.props.setPage(page);
    }
    goToAnchor() {
        if(this.props.anchor || this.props.anchor === '') {
            document.location.href = '#' + this.props.anchor;
        }

        return false;
    }
    render() {
        let pages = [];
        
        let showPages = 13;
        let start = this.state.current - (Math.floor(showPages / 2) - 1);
        let end = this.state.current + (Math.ceil(showPages / 2) + 1);

        if(start < 0) {
            end -= start;
            start = 0;
        }

        if(end > this.state.pages) {
            start += this.state.pages - end;
            end = this.state.pages;
        }

        if(start < 0) {
            start = 0;
        }

        if(start > 0) {
            pages.push(<li className={ "page-item" } key={-2}><button onClick={(e)=>{this.setPage(e); this.goToAnchor()}}  value={0} className="page-link" href="#">{"1"}</button></li>);
            if(start - 1 !== 0) {
                pages.push(<li className={"page-item disabled"} key={-1}><button onClick={(e)=>{this.setPage(e); this.goToAnchor()}}  value={0} className="page-link" href="#">{"..."}</button></li>)
            }
        }

        for(let i = start; i < end; i++) {
            pages.push(<li className={(i === this.state.current)? "page-item active" : "page-item" } key={i}><button onClick={(e)=>{this.setPage(e); this.goToAnchor()}} value={i} className="page-link" href="#">{ i + 1 }</button></li>);
        }


        if(end < this.state.pages) {
            if(end + 1 !== this.state.pages) {
                pages.push(<li className={"page-item disabled"} key={this.state.pages + 1}><button onClick={(e)=>{this.setPage(e); this.goToAnchor()}}  value={this.state.pages - 1} className="page-link" href="#">{"..."}</button></li>)
            }

            pages.push(<li className={ "page-item" } key={this.state.pages + 2}><button onClick={(e)=>{this.setPage(e); this.goToAnchor()}}  value={this.state.pages - 1} className="page-link" href="#">{this.state.pages}</button></li>);
        }

        if(this.state.pages <= 1) {
            return ( "" );
        }
        return (
            <nav className="py-3" aria-label="Page navigation">
                <ul className="pagination my-0 flex-wrap">
                    <li className={(this.state.current === 0)? "page-item disabled" : "page-item"}>
                        <button className="page-link" aria-label="Previous" onClick={(e)=>{this.previousPage(e); this.goToAnchor()}}>
                            <span aria-hidden="true">&laquo;</span>
                            <span className="sr-only">Previous</span>
                        </button>
                    </li>
                    {pages}
                    <li className={(this.state.current === this.state.pages - 1)? "page-item disabled" : "page-item"}>
                        <button className="page-link" aria-label="Next" onClick={(e)=>{this.nextPage(e); this.goToAnchor()}}>
                            <span aria-hidden="true">&raquo;</span>
                            <span className="sr-only">Next</span>
                        </button>
                    </li>
                </ul>
            </nav>
        );
    }
}

export default Pagination;