import React from 'react';
import SearchBar from './SearchBar.js';
import axios from '../bin/axios_gsh';

class SearchBarFavorite extends SearchBar{
    constructor(props) {
        super(props);

        this.updateList = this.updateList.bind(this);
    }
    
    componentDidMount() {
        let self = this;

        axios.get('product/user/favorite/available').then(function (response) {
            response = response.data;
            self.setState({
                data: response,
            });
        }).then(() => {
            this.updateList();
        });
    }
    search() {
        let self = this,
        str = this.state.searchString;

        if(str && str !== "" && str.length > 1) {
            str = '/search/' + str;
        }else{
            str = '/user/favorite/available'
        }
        axios.get('product' + str).then(function (response) {
            response = response.data;
            self.setState({
                data: response,
            });
        }).then(() => {
            self.updateList()
        });
    }
    
    updateList() {
        let searchResult = [],
        i = 0;
        
        if(this.state.data !== null && this.state.data.length > 0) {
            let sorted = [],
            searchString = (this.state.searchString)? this.state.searchString.toLowerCase() : "",
            inString = 0;

            this.state.data.forEach(product => {
                let name = ((product.Benämning).toLowerCase());

                inString = (name.search(searchString));
                if(inString === -1) {
                    inString = 999;
                }
                sorted.push({ index: i, product: product, priority: inString });
                i++;
            });

            sorted.sort(function(a, b) {
                let ak = a.priority,
                    bk = b.priority;
                if(ak < bk) return -1;
                if(ak > bk) return 1;
                return 0;
            });

            sorted.forEach(object => {
                let product = object.product;
                inString = ((product.Benämning).toLowerCase()).search(searchString);

                if(inString !== -1) {
                    product.display = [];
                    product.display.push(product.Benämning.substring(0, inString));
                    product.display.push(<b key={ i }>{ (product.Benämning).substring(inString, inString + searchString.length) }</b>);
                    product.display.push(product.Benämning.substring(inString + searchString.length, product.Benämning.length));
                }else{
                    product.display = product.Benämning;
                }

                let element = 
                    <button type="button" className="list-group-item list-group-item-action" onClick={ (e) => { delete product.display; this.state.clickFunction(e, product); } } key={ object.index } >
                        { product.display }
                    </button>;
                searchResult.push(element);
            });

        }
        
        this.setState ({
            searchResult: searchResult,
        });
    }
}

export default SearchBarFavorite;