import React from 'react';



class FavButton extends React.Component {

    render() {
        let img = "/icon/fav.png" 

        return(
            <button className={this.props.isFav? 'btn btn-gsh icon-custom-round': 'btn btn-outline-gsh icon-custom-round'} onClick={ this.props.makeFav }>
                <img src={img} alt="Favorit" />
            </button>
        );
    }
}

export default FavButton;