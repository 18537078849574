import React from 'react';

class TableRow extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            row: this.props.row,

            identifier: this.props.identifier,
            clickFunction: this.props.clickFunction
        }
    }
    static getDerivedStateFromProps(props, prevState) {
        let row = (props.row)? props.row : prevState.row,
        identifier = (props.identifier)? props.identifier : prevState.identifier,
        clickFunction = (props.clickFunction)? props.clickFunction : prevState.clickFunction;

        return ({
            row: row,
            identifier: identifier,
            clickFunction: clickFunction
        });
    }
    handleClick(event) {
        this.state.clickFunction(this.state.identifier);
    }
    render() {
        return (
            <tr className={ (this.props.inactive !== undefined && this.props.inactive)? " table-warning" : ""} onClick={((e) => this.handleClick(e))} >
                {
                    this.state.row.map((cell, i) => <td key={i} className={"align-middle" }>{cell}</td>)
                }
            </tr>
        );
    }
}

export default TableRow;