import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import SmallCart from './SmallCart';
import Tools from './Tools';

class NavTop extends Component{
    constructor(props) {
        super(props);
        this.state = {
            sitepage: (props.sitepage)? props.sitepage : '',
        };

    }

    logout(logout) {
        let self = this;
        if(logout) {
            Tools.logout().then(function (response) {
                self.props.setLogin(null);
                if(!response) {
                    // Server error
                    console.log('Error, could not sign out');
                }
            });
        }
    }
    static getDerivedStateFromProps(prevProps, prevState) {
        let sitepage = (prevProps.sitepage)? prevProps.sitepage : prevState.sitepage;

        return {
            sitepage: sitepage
        };
    }
    componentDidUpdate(prevProps, prevState) {
        if(this.props.logout !== prevProps.logout) {
            this.logout(this.props.logout);
        }
    }
    render() {
        let logAction = (this.props.user !== null) && <button onClick={(e) => {e.preventDefault(); this.logout(true);}} className="btn btn-sm btn-link ml-5" >Logga ut</button>;
            /*<Link className="btn border bg-transparent border border-success text-success" to="/login">Logga in</Link>*/;
        let cart = (this.props.user !== null) && <SmallCart totalPrice={this.props.totalPrice} deliveryDate={this.props.deliveryDate} />;
        
        return(
            <div id="main-navbar" className="px-3">
                <div className="row">
                    <div className="col-auto">
                        <div className="top-navbar-spacer">

                        </div>
                    </div>
                    <div className="col-xl col-lg-12">
                        <div className="row d-flex justify-content-center">
                            <div className="col-12 col-xl-10 d-flex">
                                {(this.props.user?
                                    <div className="ml-auto">
                                        <span className="small">
                                            Inloggad som: {this.props.user.login + " ("+this.props.user.company+")"}
                                            {logAction}
                                        </span>
                                    </div> 
                                : 
                                    <div className="ml-auto">
                                        <span className="small">
                                            <br/>
                                        </span>
                                    </div> 
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row top-navbar">
                    <div className="col-auto d-flex align-items-center">
                        <div className="top-navbar-spacer">
                            <Link className="navbar-brand mr-0 float-left" to="/">
                                <img className="mw-100 h-auto" src="/img/logo.png" id="brand" alt="Företagslogga"/>
                            </Link>
                        </div>
                    </div>
                    <div className="col-xl col-lg-12">
                        <div className="row d-flex justify-content-center py-3">
                            <div className="col-12 col-xl-10 d-flex">
                                {(this.props.user?
                                <nav className="navbar navbar-light w-100 px-0">
                                    <ul className="nav ml-xl-auto ml-0">
                                        <li className="nav-item">
                                            <Link className={"nav-link d-inline-block" + ((this.state.sitepage === 'stock' || this.state.sitepage === 'index' )? ' text-dark active' : ' text-dark')} to="/">Utbud</Link> 
                                        </li>
                                        <li className="nav-item">
                                            <Link className={"nav-link d-inline-block" + ((this.state.sitepage === 'favorites')? ' text-dark active' : ' text-dark')} to="/favorites">Favoriter</Link> 
                                        </li>
                                        <li className="nav-item">
                                            <Link className={"nav-link d-inline-block" + ((this.state.sitepage === 'lists')? ' text-dark active' : ' text-dark')} to="/lists">Inköpslistor</Link> 
                                        </li>
                                        <li className="nav-item">
                                            <Link className={"nav-link d-inline-block" + ((this.state.sitepage === 'orders')? ' text-dark active' : ' text-dark')} to="/orders">Mina ordrar</Link>  
                                        </li>
                                    </ul>
                                    <div className="ml-xl-5 ml-auto">
                                        <div className="float-left">
                                            {cart}
                                        </div>
                                        <div className="float-left">
                                            <Link rel="tooltip" data-toggle="tooltip" data-placement="bottom" title={'Konton'} style={{backgroundImage:"url('/../icon/account_circle-24px.svg')"}} className="btn btn-svg icon lg icon-custom nav-icon ml-4" to="/account">
                                            </Link>
                                        </div>
                                    </div>
                                </nav>
                                : '')}
                            </div>
                        </div>
                    </div>
                    {/*
                <div className="row top-navbar align-items-center">
                    <div className="col-12">
                        <nav className="navbar navbar-light px-3">
                            <div className="d-inline-block d-lg-none float-left">
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#sidebar" aria-controls="sidebar" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <Link className="navbar-brand mr-0 p-0" to="/">
                                    <img className="mw-100 h-auto" src="/img/logo.png" id="brand" alt="Företagslogga"/>
                                </Link>
                            </div>
                            <div className="w-100 d-inline-block d-lg-none mt-2"></div>

                            <ul className="nav float-left">
                                <li className="nav-item">
                                    <Link className="nav-link d-inline-block" to="/products">Beställning</Link> 
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link d-inline-block" to="/orders"><span className="d-xl-none">Mallar</span><span className="d-none d-xl-inline">Mina mallar</span></Link> 
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link d-inline-block" to="/order/history">Orderhistorik</Link>  
                                </li>
                            </ul>
                            <div className="float-right">
                                {logAction}
                                {cart}
                            </div>
                        </nav>
                    </div>
                </div>*/}
                </div>
            </div>
            
        );
    }
}

export default NavTop;