import React from 'react';


class Loading extends React.Component{  
    constructor(props) {
        super(props);
        
        this.state = {
            content: this.props.children,
            hasLoaded: this.props.hasLoaded
        }
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        let content = prevState.children || nextProps.children,
        hasLoaded = (nextProps.hasLoaded === undefined)? prevState.hasLoaded: nextProps.hasLoaded;

        return {
            content: content,
            hasLoaded: hasLoaded
        };
    }

    render() { 
        return (
            (this.state.hasLoaded)? this.state.content :
            <div className="py-5 text-center">
                <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        )
    }
}

export default Loading;