import React from "react";
import axios from "./bin/axios_gsh";
import { Link } from "react-router-dom";
import Table from "./components/Table";

class Order extends React.Component {
  constructor(props) {
    super(props);

    let mold = {
      meta: {
        id: "Titel",
        Status: "Status",
        DeliveryDate: "",
        ReceivedDateTime: "",
        Företag: "",
        FormateradFöretag: "",
        Email: "",
        Namn: "",
        FormateradNamn: "",
        Ordertyp: "",
        Price: "",
      },
      products: [],
      error: null,
    };

    this.state = {
      id: this.props.id,
      identifierIndex: 0,
      headers: Array.isArray(this.props.headers)
        ? this.props.headers
        : ["ID", "Artikel", "Enhet", "Antal", "Pris"],
      result: { ...mold },
      data: { ...mold },
      archived: this.props.archived,
      skipDataColumns: [0],

      hasLoaded: false,
    };
    this.loadData = this.loadData.bind(this);
  }
  componentDidMount() {
    let self = this;

    axios.get("order/status").then(function (response) {
      response = response.data;

      self.setState(
        {
          statuses: response,
        },
        () => {
          self.loadData(self.state.id).then(() => {
            self.updateData();
          });
        }
      );
    });
  }
  componentDidUpdate(prevProps) {
    let self = this;
    if (!this.state.hasLoaded) {
      this.loadData(this.state.id).then(() => {
        self.updateData();
      });
    }
  }
  loadData(id) {
    let self = this;
    return axios.get("order/" + id).then(function (response) {
      response = response.data;

      //Doesn't handle order not existing
      self.setState(
        {
          result: response,
          hasLoaded: true,
        },
        () => {
          return;
        }
      );
    });
  }

  updateData() {
    if (!this.state.result.error) {
      let meta = { ...this.state.result.meta };

      let date = new Date(meta.DeliveryDate),
        day =
          date.getUTCDate() < 10 ? "0" + date.getUTCDate() : date.getUTCDate(),
        month =
          date.getUTCMonth() + 1 < 10
            ? "0" + (date.getUTCMonth() + 1)
            : date.getUTCMonth() + 1;
      meta.DeliveryDate = day + "/" + month + "/" + date.getFullYear();

      date = new Date(meta.ReceivedDateTime);
      day =
        date.getUTCDate() < 10 ? "0" + date.getUTCDate() : date.getUTCDate();
      month =
        date.getUTCMonth() + 1 < 10
          ? "0" + (date.getUTCMonth() + 1)
          : date.getUTCMonth() + 1;
      meta.ReceivedDateTime =
        "Kl. " +
        (date.getUTCHours() < 10
          ? "0" + date.getUTCHours()
          : date.getUTCHours()) +
        ":" +
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ", " +
        day +
        "/" +
        month +
        "/" +
        date.getUTCFullYear();

      meta.Företag = meta.Företag + " ";
      meta.Namn = meta.Namn
        ? meta.Namn + (meta.Email ? " (" + meta.Email + ")" : "")
        : "Inte tillgängligt";
      meta.Info = meta.Info ? meta.Info : "...";

      let products = [];
      this.state.result.products.forEach((row) => {
        let product = {
          ID: row.ID,
          Benämning: null,
          Namn: row.Namn,
          QuantityValue: row.QuantityValue,
          Pris: null,
        };
        let orderLink = "/product/" + row.ProduktID;

        product.Benämning = <Link to={orderLink}> {row.Benämning} </Link>;
        product.Pris = row.Total
          ? row.Total.toFixed(2) + " kr"
          : "Inte tillgängligt";

        products.push(product);
      });

      this.setState({
        data: {
          meta: meta,
          products: products,
        },
      });
    }
  }

  render() {
    let onclick = function () {};

    let total = 0.0;
    if (this.state.result.products) {
      this.state.result.products.forEach((product) => {
        if (product.Total !== null) {
          total = total + parseFloat(product.Total);
        }
      });
    }

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <h1>Order</h1>
            <span className="badge badge-secondary">
              {this.state.data.meta.Status}
            </span>
            {this.state.data.error}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <dl>
              <dt>Skapad av</dt>
              <dd>
                {this.state.data.meta.Företag}
                <br />
                {this.state.data.meta.Namn}
              </dd>
              <dd></dd>
            </dl>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8 col-md-12">
            <dl>
              <dt>Leveransdatum</dt>
              <dd>{this.state.data.meta.DeliveryDate}</dd>
            </dl>
          </div>
          <div className="col-lg-4 col-md-12">
            <dl>
              <dt>Orderdatum</dt>
              <dd>{this.state.data.meta.ReceivedDateTime}</dd>
            </dl>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <dl>
              <dt>Ordermeddelande</dt>
              <dd>{this.state.data.meta.Info}</dd>
            </dl>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="border rounded">
              <div className="px-0 col-md-12">
                <Table
                  hasLoaded={this.state.hasLoaded}
                  clickFunction={onclick}
                  visualFeedback={false}
                  countRows={true}
                  headers={this.state.headers}
                  data={this.state.data.products}
                  skipDataColumns={this.state.skipDataColumns}
                  identifierIndex={this.state.identifierIndex}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 text-right">
            <div className="text-right pt-3">
              {"Totalt: " +
                (total === 0.0
                  ? "Inte tillgängligt"
                  : total.toFixed(2) + " kr")}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Order;
