import React from 'react';
import axios from '../bin/axios_gsh';
import Table from './Table.js';

class OrderHistory extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            headers: ['ID','Skapad', 'Skapad av', 'Skapad av', 'Status'],
            columnSizing: ['1%', '140px', '1%', null, '140px'],
            months: ['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December'],
            display: 1,
            startYear: new Date(),
            oldestOrder: new Date().getUTCFullYear(),

            onClickFunction: this.props.onClickFunction,
            skipDataColumns: [0, 2],
            data: [],
        };
        this.showOlder = this.showOlder.bind(this);
        this.changeYear = this.changeYear.bind(this);
    }
    /*
    static getDerivedStateFromProps(nextProps, prevState) {
        let data = nextProps.data || prevState.data;
        let statuses = nextProps.statuses || prevState.statuses;
        let startYear = nextProps.startYear || prevState.startYear;
        let display = nextProps.display || prevState.display;

        return {
            data: data,
            statuses: statuses,
            startYear: startYear,
            display: display,
        };
    }
    */
    componentWillUnmount() {
        
    }
    componentDidMount() {
        let self = this;
        axios.get('order/history').then(function (history) {
            history = history.data;
            let current = new Date();
            
            let yearOfOldestOrder = parseInt(new Date().getUTCFullYear(), 10);
            if(history.length > 0) {
                yearOfOldestOrder = parseInt((new Date(history[history.length - 1].ReceivedDateTime)).getUTCFullYear(), 10);
            }
            
            if(((parseInt(current.getUTCFullYear(), 10)) - yearOfOldestOrder) === 0) {
                yearOfOldestOrder = current.getUTCFullYear();
            }
            self.setState({
                result: history,
                oldestOrder: yearOfOldestOrder
            } , () => {
                let data = self.loadData(history);

                self.setState({
                    data: data,
                });
            });
        });
    }
    changeYear(e) {
        let date = this.state.startYear;
        date.setUTCFullYear(parseInt(e.currentTarget.value, 10));

        this.setState({
            startYear: (date),
            display: 1
        }, () => {
            let data = this.loadData(this.state.result);
            this.setState({
                data: data,
            });
        });
    }
    loadData(history) {
        history = [...history];
        let self = this,
        years = [];
        for (let i = 0; i < (new Date().getUTCFullYear()-this.state.oldestOrder) + 1; i++) {
            let months = [],
            previousYear = new Date();
            previousYear.setUTCFullYear(previousYear.getUTCFullYear() - i);

            
            for (let j = (previousYear.getUTCFullYear() === (new Date()).getUTCFullYear() && i === 0)? parseInt(previousYear.getUTCMonth(), 10) : 11; j >= 0; j--) {
                let previousMonth = previousYear,
                monthArr = [];
                previousMonth.setUTCMonth(j);

                for (let k = 0; k < history.length; k++) {
                    const order = history[k];
                    let orderMonth = new Date(order.ReceivedDateTime);
    
                    if(previousMonth.getUTCFullYear() === orderMonth.getUTCFullYear() && previousMonth.getUTCMonth() === orderMonth.getUTCMonth()) {
                        let date = orderMonth;
                        let day = (date.getDate() < 10)? "0" + date.getDate() : date.getDate(),
                        month = (date.getMonth() + 1 < 10)? "0" + (date.getMonth()+1) : (date.getMonth()+1);
    
                        date = day + '/' + month + '/' + date.getFullYear();

                        let formatedOrder = {
                            ID: order.ID,
                            ReceivedDateTime: date,
                            ID_Användare_Plockatav: order.ID_Användare,
                            Namn: order.Namn,
                            Status: order.Status
                        }
                        monthArr.push(formatedOrder);
                    }
                }
                months.push({ month: self.state.months[previousMonth.getUTCMonth()], data: monthArr });
            }
            years.push({year: previousYear.getUTCFullYear(), data: months});
        }
        return years;
    }
    showOlder(e) {
        let display = parseInt(this.state.display, 10) + 1;
        
        this.setState({
            display: display
        }, () => {
            let data = this.loadData(this.state.result);
            this.setState({
                data: data,
            });
        });
    }
    render() {
        let self = this;
        let onclick = function(data) {
            self.state.onClickFunction(data);
        };

        let years = [];
        let current = new Date().getFullYear();
        let oldestOrder = this.state.oldestOrder;
        let difference = current - oldestOrder;

        for (let i = 0; i < difference + 1; i++) {
            let count = 0;

            if(this.state.data.length > 0 && this.state.data[i].data) {
                for(let j = 0; j < this.state.data[i].data.length; j++) {
                    count += this.state.data[i].data[j].data.length;
                }
            }
            
            let year = {
                number: current - i,
                count: count
            };
            years.push(year);
        }

        let display = this.state.display;
        return(
            <div className="row">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-12">
                            <h1>
                                Orderhistorik
                            </h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="accordion" id="OrderHistory">
                                    
                                        { this.state.data.map((year, i) => 
                                            (year.year <= self.state.startYear.getUTCFullYear() && display !== 0 && display--)?
                                            <div key={ i }>
                                                <h3>{(i !== 0 && this.state.display !== display+i)? <hr/> : ''}{ year.year }</h3>
                                                { year.data.map((month, j) => 
                                                    <div rel="tooltip" data-toggle="tooltip" data-placement="bottom" title="Öppna" className="card mb-1 border-bottom" key={ j }>
                                                        <div className="card-header border-0 p-0" id={ "heading" + j }>
                                                            <h5 className="mb-0">
                                                                <button className={ "btn btn-light justify-content-between text-left w-100 rounded-0 py-3 px-4" + ((j !== 0)? " collapsed" : "") } type="button" data-toggle="collapse" data-target={ "#collapse" + j } aria-expanded="false" aria-controls={ "collapse" + j }>
                                                                    { month.month }
                                                                    <span className="badge badge-primary align-middle float-right my-1">{ (month.data.length !== 0)? month.data.length : "" }</span>
                                                                </button>
                                                            </h5>
                                                        </div>

                                                        <div id={ "collapse" + j } className={ "collapse multi-collapse"}>
                                                            {((month.data.length > 0)? 
                                                                <div className="card-body p-0">
                                                                    <div className="border-top">
                                                                        <Table clickFunction={ onclick } columnSizing={this.state.columnSizing} visualFeedback={ true } countRows={ false } headers={ this.state.headers } data={ month.data } skipDataColumns={ this.state.skipDataColumns } identifierIndex={ 0 } />
                                                                    </div>
                                                                </div>
                                                            : '')}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            : ''
                                        )}
                                    </div>
                                    <hr/>
                                    <button onClick={ this.showOlder } type="button primary" className="btn btn-primary btn-lg btn-block" >Visa äldre</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <div className="row">
                                <div className="col-md-12">
                                    <hr className="d-lg-none mt-4"/>
                                    <h2 className="text-muted">Visa år</h2>
                                    <p>Välj ett år för att visa order lagd under valt år.</p>
                                    <div className="list-group">
                                        {
                                            years.map((year, i) => 
                                                <button onClick={ this.changeYear } type="submit primary" className={ "list-group-item list-group-item-action" + ((this.state.startYear.getFullYear() === parseInt(year, 10))? ' bg-primary text-white' : '') } value={ year.number } key={ i }>
                                                    <h6 className="my-0">
                                                        { year.number }
                                                        <span className="badge badge-primary align-middle float-right">{ year.count }</span>
                                                    </h6>
                                                </button>
                                           )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default OrderHistory;