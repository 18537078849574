import React from 'react';

class Form extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            sublabel: this.props.sublabel,
            send: {

            },
            inputStyle: {
                'danger': "form-control border border-danger",
                'warning': "form-control border border-warning",
                'default': "form-control"
            },
            textHelpStyle: {
                'danger': "visible",
                'default': "invisible"
            }
        }

        this.beforeChange = this.beforeChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.afterChange = this.afterChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    beforeChange(event) {
        // Ran before handleChange
        //Override
    }
    handleChange(event) {
        this.beforeChange(event);
        this.setState({
            [event.target.name]: event.target.value
        }, () => {
            this.afterChange(event)
        });
    }
    afterChange(event) {
        // Ran after handleChange
        // Override
    }
    handleSubmit(event) {
    }

    render() {
        return(
            <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <label for=""></label>
                  <select className="form-control" value={this.state.value} onChange={this.handleChange} name="gh_select" id="gh_select">
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                  </select>
                </div>
                <div className="form-group row">
                    <input type="password" value={this.state.value} onChange={this.handleChange} className="form-control" name="gh_username" id="gh_username" placeholder="Användarnamn"/>
                    <small id="helpId" className="form-text text-muted">Case sensitive</small>
                </div>
                <div className="form-group row">
                    <button type="primary" className="btn btn-primary col-md-12">Logga in</button>
                </div>
            </form>
        );
    }
}

export default Form;