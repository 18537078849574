import React from 'react';

class DisplayError extends React.Component {
    render() {
        return (
            <div className={((this.props.type)? "alert alert-"+this.props.type : "alert alert-warning") } role="alert">
                <p className="mb-0">{this.props.title}:<br/>{this.props.description}</p>
            </div>
        )
    }
}

export default DisplayError;
