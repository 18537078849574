import React from 'react';
import axios from './../bin/axios_gsh';
import Table from './Table.js';
import Pagination from './Pagination.js';

class Orders extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            headers: ['ID', 'Leveransdatum', 'Skapad', 'Skapad av', 'Artiklar', 'StatusID', 'Status'],
            columnSizing: ['1%', '140px', '120px', null, '140px', '1%', '140px'],
            onClickFunction: this.props.onClickFunction,

            identifierIndex: 0,
            skipDataColumns: [0, 5],
            data: [],
            hasLoaded: false,
            page: 0,
            perPage: 5,
        };
    }
    componentDidMount() {
        let self = this;
        
        ( axios.get('order/status') ).then(function (response) {
            
            let statuses = response.data;
            
            axios.get('order/company').then(function (activeOrders) {
                activeOrders = activeOrders.data;

                activeOrders.forEach(row => {
                    let date = new Date(row.ReceivedDateTime);
                    let day = (date.getDate() < 10)? "0" + date.getDate() : date.getDate(),
                    month = (date.getMonth() + 1 < 10)? "0" + (date.getMonth()+1) : (date.getMonth()+1);

                    row.ReceivedDateTime = day + '/' + month + '/' + date.getFullYear();

                    date = new Date(row.DeliveryDate);
                    day = (date.getDate() < 10)? ("0" + date.getDate()) : (date.getDate());
                    month = (date.getMonth() + 1 < 10)? ("0" + (date.getMonth()+1)) : (date.getMonth()+1);

                    row.DeliveryDate = day + '/' + month + '/' + date.getFullYear();

                    statuses.forEach(status => {
                        if (row.ID_Status === status.ID) {
                            row.Status = status.Text;
                        }
                        
                    });
                });

                self.setState({
                    redirect: false,
                    data: activeOrders,
                    hasLoaded: true
                });
            });
        }); 
    }
    componentWillUnmount() {
    }
    render() {
        let self = this;
        let onclick = function(data) {
            self.state.onClickFunction(data);
        };
        
        if(this.state.redirect) {
            return this.state.redirect;
        }

        let setPage = function(page) {
            self.setState({
                page: page,
            });
        };

        return (
            <div className="row">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-12">
                            <h1>
                                Ordrar under process
                            </h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="border rounded">
                                <div className="px-0 col-md-12">
                                    <Table page={this.state.page} columnSizing={this.state.columnSizing} perPage={this.state.perPage} hasLoaded={this.state.hasLoaded} clickFunction={ onclick } tooltip={ "Öppna" } visualFeedback={ true } countRows={ false } headers={ this.state.headers } data={ this.state.data } skipDataColumns={ this.state.skipDataColumns } identifierIndex={ this.state.identifierIndex } />
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-md-12">
                            <Pagination setPage={setPage} current={this.state.page} pages={((this.state.data.length / this.state.perPage) % 1 !== 0)? Math.floor(this.state.data.length / this.state.perPage) + 1 : Math.floor(this.state.data.length / this.state.perPage)}>

                            </Pagination>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Orders;